<template>
  <section>
    <header class="container custom-container bordercustom">
      <ul class="appbar mb-0">
        <li></li>
        <li class="text-center">Login</li>
      </ul>
    </header>
    <v-container class="custom-container">
      <div class="boxauthoriation">
        <v-row>
          <v-col md="12" class="logo-space">
            <v-row class="logo flex justify-center">
              <router-link to="/login/user">
                <img src="@/assets/images/logoheader.png" alt="" />
              </router-link>
            </v-row>
          </v-col>
          <v-col md="12">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              class="form-section"
            >
              <v-row>
                <v-col md="12" cols="12">
                  <v-text-field
                    v-model="email"
                    label="Email"
                    :rules="emailRules"
                    @keyup.enter.stop="validate"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="12" cols="12">
                  <v-text-field
                    v-model="password"
                    label="Password"
                    :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required]"
                    :type="showpassword ? 'text' : 'password'"
                    @click:append="showpassword = !showpassword"
                    @keyup.enter.stop="validate"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="submitbtn">
                  <v-btn
                    :disabled="!valid"
                    class="submitbutton btn-dark"
                    @click="validate"
                    v-if="!loading"
                  >
                    Login
                  </v-btn>

                  <v-progress-circular
                    v-if="loading"
                    indeterminate
                  ></v-progress-circular>
                  <v-snackbar
                    v-model="snackbar"
                    v-if="allLogin.data != undefined"
                    color="red"
                    width="100%"
                  >
                    <!-- {{ allVerify.data.message }} -->
                    {{ allLogin.data.message }}
                    <template v-slot:action="{ attrs }">
                      <v-btn text v-bind="attrs" @click="snackbar = false">
                        <v-icon> mdi-close </v-icon>
                      </v-btn>
                    </template>
                  </v-snackbar>
                  <ul class="socialIconsLogin">
                    <li @click="logInWithFacebook">
                      <Icon iconName="facebook" />
                    </li>
                    <li>
                      <GoogleLogin
                        :params="params"
                        :onSuccess="onSuccess"
                        :onFailure="onFailure"
                      >
                        <Icon iconName="google" />
                      </GoogleLogin>
                    </li>
                  </ul>
                  <div class="forgetPasswordLink">
                    <router-link
                      :to="
                        $route.params.slug == 'user'
                          ? '/forgetPassword/user'
                          : '/forgetPassword/business'
                      "
                    >
                      Forgot your password?
                    </router-link>
                  </div>
                  <div class="registerlink">
                    <div class="haveAccount">
                      Don't have an account?
                      <router-link
                        :to="
                          $route.params.slug == 'user'
                            ? '/registration/user'
                            : '/registration/business'
                        "
                        ><strong>Sign Up</strong></router-link
                      >
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GoogleLogin from "vue-google-login";
import Icon from "@/components/Icon";
export default {
  name: "Login",
  data() {
    return {
      valid: true,
      loading: false,
      email: "",
      password: "",
      snackbar: false,
      showpassword: false,
      emailRules: [(v) => !!v || "Email is required."],
      rules: {
        required: (value) => !!value || "Password is required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      params: {
        client_id:
          "129953055617-4rnna0dlo55hjcf85l8rvtnphocl8rmm.apps.googleusercontent.com",
      },
    };
  },
  components: {
    Icon,
    GoogleLogin,
  },
  methods: {
    ...mapActions(["login", "socialLogin"]),
    notify() {
      this.$notification.show(
        "Hello World",
        {
          body: "This is an example!",
        },
        {}
      );
    },
    async validate() {
      this.loading = true;
      if (this.$refs.form.validate()) {
        if (this.$route.params.slug == "user") {
          let userData = {
            email: this.email,
            password: this.password,
          };
          await this.login(userData);
          this.snackbar = true;
        } else {
          let userData = {
            email: this.email,
            password: this.password,
          };
          await this.businessLogin(userData);
          this.snackbar = true;
        }
      }
      this.loading = false;
    },
    reset() {
      this.$recaptchaLoaded().then(() => {
        this.$recaptcha("login").then((token) => {
          console.log(token);
        });
      });
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    logout() {
      this.$store.dispatch("logoutUser");
    },
    async initFacebook() {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: "3516614005029920", //You will need to change this
          cookie: true, // This is important, it's not enabled by default
          version: "v8.0",
        });
      };
    },
    async logInWithFacebook() {
      await this.loadFacebookSDK(document, "script", "facebook-jssdk");
      await this.initFacebook();
      let that = this;
      window.FB.login(function (response) {
        if (response.authResponse) {
          window.FB.api(
            "/me",
            "GET",
            { fields: "id,name,email" },
            async (userInformation) => {
              let n = userInformation.name.split(" ");
              // console.log(userInformation);
              let socialData = {
                socialLogin: "facebook",
                accessToken: userInformation.id,
                fullname: n[0] + n[1],
                email: userInformation.email,
              };
              // console.log(userInformation);
              await that.socialLogin(socialData);
            }
          );
        }
      });
      return false;
    },
    async loadFacebookSDK(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    },

    async onSuccess(googleUser) {
      let data = googleUser.getBasicProfile();

      let n = Object.values(data)[1].split(" ");

      let b = [];
      Object.keys(data).forEach(function (k) {
        b.push(data[k]);
      });

      let socialData = {
        socialLogin: "google",
        accessToken: b[0],
        fullname: n[0] + n[1],
        email: data.$t,
      };

      await this.socialLogin(socialData);
    },
    onFailure(googleUser) {
      console.log(googleUser);
    },
  },
  computed: {
    ...mapGetters(["allLoadingAuth", "allLogin", "allBusinessLogin"]),
  },
  async created() {
    this.snackbar = true;
    if (localStorage.getItem("token") != undefined) {
      this.$router.push("/");
    }
    // window.fbAsyncInit = function () {
    //   this.FB.init({
    //     appId: "3516614005029920",
    //     cookie: true,
    //     xfbml: true,
    //     version: "v8.0",
    //   });

    //   this.FB.AppEvents.logPageView();
    // };

    // (function (d, s, id) {
    //   var js,
    //     fjs = d.getElementsByTagName(s)[0];
    //   if (d.getElementById(id)) {
    //     return;
    //   }
    //   js = d.createElement(s);
    //   js.id = id;
    //   js.src = "https://connect.facebook.net/en_US/sdk.js";
    //   fjs.parentNode.insertBefore(js, fjs);
    // })(document, "script", "facebook-jssdk");
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.socialIconsLogin {
  display: flex;
  width: 100%;
  padding: 10px;
  justify-content: center;
  margin-bottom: 0;
  margin-top: 15px;
  margin-bottom: -10px;
  li {
    box-shadow: 1px 0px 5px #efefef;
    padding: 5px 12px;
    margin: 0 5px;
    cursor: pointer;
    div {
      margin-top: 8px;
      display: block;
    }
  }
}
.boxauthoriation {
  padding: 30px 15px;
  // width: 450px;
  border-radius: 10px !important;
}
.forgetPasswordLink {
  margin-top: 25px;
  font-size: 14px;
  a {
    color: black;
    text-decoration: underline;
  }
}
.registerlink {
  margin-top: 20px;
  font-size: 14px;
  .haveAccount {
    font-size: 12px;
    margin-bottom: 15px;
    @media only screen and (min-width: 992px) {
      font-size: 14px;
    }
  }
  a {
    color: black;
  }
}
.logo {
  img {
    width: 200px;
  }
  @media only screen and (max-width: 992px) {
    display: none;
  }
}
.submitbtn {
  text-align: center;
  @media only screen and (min-width: 991px) {
    padding-left: 0;
    padding-right: 0;
  }
}

img {
  width: 150px;
}
.form-section {
  .col-md-6,
  .col-md-12 {
    padding: 0 20px !important;
  }
}
.titleform {
  margin-bottom: 20px;
  span {
    text-transform: capitalize;
  }
}

.btn-dark {
  background: $btnColor !important;
  width: 95%;
  height: 50px !important;
  @media only screen and (min-width: 991px) {
    width: 97%;
  }
}
.v-snackbar {
  color: red !important;
}
</style>
